// import './App.css';
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import MainPage from './main-page/main-page';
import Pages from './pages/pages';
import ContentPage from './content-page/content-page';
import apiClient from "./instance/api-client";
import Pages404 from './404-page/404-page';
import AllPages from './pages/all-pages';

function App() {
  const [pages, setPages] = useState([])
  const [categories, setCategories] = useState([])

  useEffect(() => {
    loadPagesList()
  }, [])

  useEffect(() => {
    const loadCategoryList = async () => {

      try {
        const response = await apiClient.get('categories', { params: { take: 99999 } })

        const filteredArray = response.data.data.filter((category) => {
          return !pages.some((item2) => item2.subPage.some((item3) => item3.categories.name === category.name));
        });

        const filteredArray2 = filteredArray.filter((category) => {
          return !pages.some((item2) => item2.categories.name === category.name)
        })

        setCategories(filteredArray2)
      } catch (err) {
        console.info(err)
      }
    }
    loadCategoryList()
  }, [pages])


  const loadPagesList = async () => {

    try {
      const response = await apiClient.get('pages')
      setPages(response.data)
    } catch (err) {
      console.info(err)
    }
  }

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={<MainPage />}
        />

        <Route
          exact
          path="/ContentPage"
          element={<ContentPage />}
        />

        <Route
          exact
          path="/Pages"
          element={<Pages />}
        />

        {/* dynamic get route from server pages */}
        {pages.flatMap((page, index) => {
          return (
            <React.Fragment key={index}>
              <Route
                key={page.categories.name}
                exact
                path={`/${page.categories.urlName ? page.categories.urlName : page.categories.name}`}
                element={<AllPages />}
              />

              {/* <Route
                key={page.categories.name + index}
                exact
                path={`/${page.categories.urlName ? page.categories.urlName : page.categories.name}/:category`}
                element={<ContentPage />}
              /> */}

              {page.subPage && page.subPage.map((subPage, subIndex) => {
                const title = subPage.categories.urlName ? subPage.categories.urlName : subPage.categories.name;

                return (
                  <React.Fragment key={subIndex}>
                    <Route
                      key={title}
                      exact
                      path={`/${title}`}
                      element={<Pages />}
                    />

                    {/* <Route
                      key={title + subIndex}
                      exact
                      path={`/${title}/:category`}
                      element={<ContentPage />}
                    /> */}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        })}

        {categories && categories.flatMap((category, index) => {
          const title = category.urlName ? category.urlName : category.name;

          return (
            <React.Fragment key={index}>
              <Route
                key={title}
                exact
                path={`/${title}`}
                element={<Pages />}
              />

              {/* <Route
                key={title + index}
                exact
                path={`/${title}/:category`}
                element={<ContentPage />}
              /> */}
            </React.Fragment>
          );
        })}

        <Route
          exact
          path={`/article/news/:category`}
          element={<ContentPage />}
        />

        {/* redirect to main page if no route been found */}
        <Route
          exact
          path={`*`}
          element={<Pages404 />}
        />
        <Route
          exact
          path={`/404`}
          element={<Pages404 />}
        />
      </Routes>
    </Router>
  );
}

export default App;
