import React from "react"
import { useNavigate } from "react-router-dom";

export default function ListViewComponent(props) {
    const {  content, keys } = props
    const redirect = useNavigate()

    // const formatDate = (dateString) => {
    //     const options = { month: "numeric", day: "numeric", year: "numeric" };
    //     const locale = 'zh-CN';
    //     const formattedDate = new Date(dateString).toLocaleDateString(locale, options);
    //     const parts = formattedDate.split(/[\u200E/]/).filter(part => part.trim() !== '');
    //     return `${parts[1]} 月 ${parts[2]} 日`;
    // }

    return (
        <React.Fragment key={keys}>
            <div className="flex flex-wrap items-center md:-mx-2" onClick={() => redirect(`/article/news/${content.permLink}`)}>

                <div className="w-full md:w-3/12">
                    <div className="md:mx-2">
                        <a><img src={`${process.env.REACT_APP_API_URL}uploads/${content.image.filename}`} className="w-full mb-4 md:mb-0" alt="" /></a>
                    </div>
                </div>
                <div className="md:w-9/12 text-truncate" style={{minWidth:'unset',WebkitLineClamp:'unset'}} >
                    <div className="md:mx-2 ">
                        <h2 className="text-white text-2xl pb-2 leading-tight"><a className="text-white " >{content.title}</a></h2>
                        <p className="text-gray-600 pb-2 sub-title ">
                            {content.metaDescription}
                        </p>
                        <a className="continue-reading2 hover:text-red-600">阅读更多 →</a>
                        {/* <div className="text-gray-200x inline-block py-1 italic text-sm">发表于: <time dateTime="2021-11-19 20:00">{formatDate(content.createdAt)}</time> 由 <a className="underline hover:no-underline">{content.users_name}</a></div> */}
                    </div>
                </div>
                <div className="w-full mb-5 pb-5 border-b border-gray-300 md:mx-2"></div>
            </div>
        </React.Fragment>)
}