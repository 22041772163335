import { UPDATED_CONTENT, CLEAR_UPDATED_CONTENT } from '../action/type/type';

const initialState = {
    post: {
        content: null,
        category: null
    }
};


const contentReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATED_CONTENT:
            return {
                post: {
                    content: action.content, // Update content
                    category: action.category // Update category
                }
            };
        case CLEAR_UPDATED_CONTENT:
            return {
                post: {
                    content: null, // Clear content
                    category: null, // Clear category
                }
            };
        default:
            return state;
    }
};


export default contentReducer;