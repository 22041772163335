import React from 'react'
import Header from '../component/header/header'
import styled, { keyframes } from 'styled-components';


const bounceAnimation = keyframes`
  0% {
    transform: translateY(30%) rotateZ(0deg);
    height: 50%;
    width: 110%;
  }
  15% {
    transform: translateY(0%) rotateZ(-20deg);
    height: 100%;
    width: 100%;
  }
  35% {
    transform: translateY(-160%) rotateZ(-160deg);
  }
  50% {
    transform: translateY(-170%) rotateZ(-180deg);
  }
  65% {
    transform: translateY(-160%) rotateZ(-200deg);
  }
  85% {
    transform: translateY(0%) rotateZ(-340deg);
    height: 100%;
    width: 100%;
  }
  100% {
    transform: translateY(30%) rotateZ(-360deg);
    height: 50%;
    width: 110%;
  }
`;

const expandShadowAnimation = keyframes`
  0%, 10%, 90%, 100% {
    width: 0%;
    height: 0%;
  }
  35%, 65% {
    width: 120%;
    height: 40%;
  }
  50% {
    width: 130%;
    height: 44%;
  }
`;

const Container = styled.div`
  display: inline-grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 50px;
  width: 50px;
  vertical-align: middle;
  margin: 0;
  transform: translate(-50%, -100%);
`;

const Ball = styled.svg`
  height: 50px;
  width: 50px;
  position: relative;
  animation: ${bounceAnimation} 1500ms infinite cubic-bezier(0.61, 0.41, 0.43, 0.68);
`;

const Shadow = styled.div`
  height: 20px;
  width: 40px;
  background: #0000002b;
  border-radius: 50%;
  animation: ${expandShadowAnimation} 1500ms infinite cubic-bezier(0.61, 0.41, 0.43, 0.68);
  position: absolute;
  bottom: 0;
`;

const Error = styled.div`
font-size: 150px;
  color: #ffffff;
  margin: 2rem 0;
  text-shadow: 
  1px 1px 1px #dddddd, 
  2px 2px 1px #cfcfcf, 
  3px 3px 1px #c5c5c5, 
  4px 4px 1px #bdbdbd, 
  5px 5px 1px #a3a3a3, 
  6px 6px 1px #8d8d8d, 
  7px 7px 1px #7b7b7b, 
  8px 8px 1px #696969, 
  25px 25px 8px rgba(0, 0, 0, 0.2);
`;

const TextError = styled.div`
font-size: 30px;
  font-weight: 600;
  color: #444;
  margin: 2rem 0;
  margin: 0;
  padding: 0;
  text-decoration: none;
`;

const Body = styled.div`
margin: 0;
  padding: 0;
  text-align: center;
  font-family: sans-serif;
  background: ghostwhite;
  `;

const Page = styled.div`
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
  `;

const BackHome = styled.a`
  
    display: inline-block;
    border: 2px solid #222;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.75rem 1rem 0.6rem;
    transition: all 0.2s linear;
    box-shadow: 0 15px 15px -11px rgba(0,0,0, 0.4);
    background: #222;
    border-radius: 6px;
  
    &:hover {
    background: #222;
    color: #ddd;
    }
  `;

const Page404 = () => {
  return (
    <Body >
      <Header pageCallBack={() => { }} />
      <div style={{ padding: '1rem 2rem' }}>
        <TextError >Error</TextError>
        <Error >404</Error>
        <Container>
          <Ball xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' preserveAspectRatio="none">
            <path d='M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48ZM399,352H353.78a8,8,0,0,1-6.91-4l-16.14-27.68a8,8,0,0,1-.86-6l14.86-59.92a8,8,0,0,1,4.65-5.45l28.1-11.9a8,8,0,0,1,8.34,1.3l41.63,35.82a8,8,0,0,1,2.69,7.26,174.75,174.75,0,0,1-24.28,66.68A8,8,0,0,1,399,352ZM134.52,237.13l28.1,11.9a8,8,0,0,1,4.65,5.45l14.86,59.92a8,8,0,0,1-.86,6L165.13,348a8,8,0,0,1-6.91,4H113a8,8,0,0,1-6.82-3.81,174.75,174.75,0,0,1-24.28-66.68,8,8,0,0,1,2.69-7.26l41.63-35.82A8,8,0,0,1,134.52,237.13Zm256.94-87.24-18.07,51.38A8,8,0,0,1,369,206l-29.58,12.53a8,8,0,0,1-8.26-1.24l-56.26-47.19A8,8,0,0,1,272,164V130.42a8,8,0,0,1,3.56-6.65l42.83-28.54a8,8,0,0,1,7.66-.67A176.92,176.92,0,0,1,390,142,8,8,0,0,1,391.46,149.89ZM193.6,95.23l42.84,28.54a8,8,0,0,1,3.56,6.65V164a8,8,0,0,1-2.86,6.13l-56.26,47.19a8,8,0,0,1-8.26,1.24L143,206a8,8,0,0,1-4.43-4.72l-18.07-51.38A8,8,0,0,1,122,142a176.92,176.92,0,0,1,64-47.48A8,8,0,0,1,193.6,95.23Zm17.31,327.46L191.18,373a8,8,0,0,1,.52-7l15.17-26a8,8,0,0,1,6.91-4h84.44a8,8,0,0,1,6.91,4l15.18,26a8,8,0,0,1,.53,7l-19.59,49.67a8,8,0,0,1-5.69,4.87,176.58,176.58,0,0,1-79,0A8,8,0,0,1,210.91,422.69Z' />
          </Ball>
          <Shadow />
        </Container>

        <Page >哎呀！！找不到網頁</Page>
        <BackHome href="/">返回主页</BackHome>
      </div>

    </Body>

  );
};

export default Page404;