
import axios from "axios";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,//'http://127.0.0.1:40000/',
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});
apiClient.interceptors.request.use(function (config) {
    // Do something before request is sent
    let authKey = sessionStorage.getItem('token')
    if (authKey != null)
        config.headers["Authorization"] = "Bearer " + authKey;
    return config;
});


export default apiClient;