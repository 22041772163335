import React, { useEffect, useState } from "react"
import apiClient from "../../instance/api-client"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SideWidgetHeadline(props) {
    const redirect = useNavigate()
    const { title, category } = props
    const [contents, setContents] = useState(null)
    const [categories, setCategories] = useState({
        name: null,
        urlName: null,
    })

    const updatedPost = useSelector(state => state.content.post)
    useEffect(() => {
        if (updatedPost.category) {
            const result = updatedPost.category.filter((categorys) => (categorys.name === props.category || categorys.urlName === props.category))

            if (result.length > 0) {
                const oldContent = [
                    ...contents,
                ]

                const newContentExist = oldContent.filter((content) => content.id === updatedPost.content.id)

                if (newContentExist.length === 0) {
                    const newContent = [
                        updatedPost.content,
                        ...oldContent
                    ]
                    setContents(null)
                    setContents(newContent.slice(0, 4))
                }
            }
        }
    }, [updatedPost])

    useEffect(() => {
        const loadCategoryPost = async () => {
            try {
                const response = await apiClient.get('contents/category/' + category, { params: { take: 3} })

                if (response.status === 200) {

                    if (response.data.data[0]) {
                        setCategories({
                            name: response.data.data[0].categories[0].name,
                            urlName: response.data.data[0].categories[0].urlName
                        })
                        const data = response.data.data//[0].contents.filter((content) => (content.id != null));
                        setContents(data)
                    }
                }
            } catch (error) {
                console.info(error)
            }
        }
        loadCategoryPost()
    }, [title, category])


    return (<>
        <div className="widget sm:col-span-6 lg:col-span-4 aside-borders p-5">
            <div className="widget__header">
                <span className="widget__header-title border-b">{title}</span>
                <a onClick={() => redirect(`/${categories.urlName ? categories.urlName : categories.name}`)} className="widget__header-link border-b">查看更多</a>
            </div>
            <ol className="widget__list">
                {contents && contents.flatMap((content, index) => (
                    <li className="text-gray-900 font-medium hover:text-red-600 leading-none" key={index}>
                        <a onClick={() => redirect(`/article/news/${content.permLink}`)}>
                            {content.title}</a>
                    </li>
                ))}

                {/* <li className="text-gray-900 font-medium hover:text-red-600 leading-none"><a href=""><strong>英超金靴的聚集 –
                </strong>孙兴慜和萨拉赫作为昔日英超金靴得主，将在沙特联赛中重聚，引发了球迷们的关注和猜测。</a></li>
                <li className="text-gray-900 font-medium hover:text-red-600 leading-none"><a href=""><strong>中东足球的崛起 –
                </strong>这一举动将提升中东地区足球的国际影响力，加强该地区与欧洲足球的联系。</a></li> */}
            </ol>
        </div>
    </>)
}