/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "../../instance/api-client";

export default function Header({ pageCallBack }) {
    const redirect = useNavigate();
    // get pages from server and display to header
    // const multiRoute = ["Hot", "News", "Sport"]
    const [isMenuActive, setIsMenuActive] = useState(false);
    const [isSubMenuActive, setIsSubMenuActive] = useState(false);
    const [subMenu, setSubMenu] = useState(null)
    const [menuTitle, setMenuTitle] = useState('');
    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'enabled');
    const [pages, setPages] = useState(null)

    const toggleMenu = useCallback(() => {
        setIsMenuActive((prev) => !prev);
        if (isMenuActive) {
            setIsSubMenuActive(false);
            setMenuTitle('');
        }
    }, [isMenuActive]);

    const showSubMenu = (children) => {
        const tempSubMenu = children.querySelector(".submenu");
        tempSubMenu.classList.add("is-active");
        setSubMenu(tempSubMenu)

        setIsSubMenuActive(true);
        setMenuTitle(children.querySelector('i').parentNode.childNodes[0].textContent);
    };

    const hideSubMenu = () => {
        subMenu.classList.remove("is-active");
        setSubMenu(null)
        setIsSubMenuActive(false);
        setMenuTitle('');
    };

    const toggleSubMenu = (e) => {
        if (!isMenuActive) return;
        if (e.target.closest('.menu-dropdown')) {
            const children = e.target.closest('.menu-dropdown');
            showSubMenu(children);
        }
    };

    useEffect(() => {
        loadPagesList()
    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 992 && isMenuActive) {
                toggleMenu();
            }
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [isMenuActive, toggleMenu]);

    useEffect(() => {
        if (darkMode) {
            document.body.classList.add('darkmode');
            localStorage.setItem('darkMode', 'enabled');
        } else {
            document.body.classList.remove('darkmode');
            localStorage.removeItem('darkMode');
        }
    }, [darkMode]);

    const toggleDarkMode = () => {
        setDarkMode((prev) => !prev);
    };


    const loadPagesList = async () => {
        try {
            const response = await apiClient.get('pages')
            setPages(response.data)

        } catch (err) {
            console.info(err)
        }
    }

    useEffect(() => {
        if (pages)
            pageCallBack(pages)
    }, [pages])

    const handleDecodeUrlName = (category) => {
        const title = category.urlName ? category.urlName : category.name
        redirect(`/${title}`)
    }

    return (
        <>
            <header className="header" id="header">
                <nav className="navbar container">
                    <section className="navbar-left">
                        <a onClick={() => redirect('/')} className="brand"><img src="/images/te1554-72res.png" alt="" /></a>
                        <div className="burger" id="burger" onClick={toggleMenu}>
                            <span className="burger-line"></span>
                            <span className="burger-line"></span>
                            <span className="burger-line"></span>
                        </div>
                    </section>
                    <section className="navbar-center">
                        <span className={`overlay ${isMenuActive ? 'is-active' : ''}`} onClick={toggleMenu}></span>
                        <div className={`menu ${isMenuActive ? 'is-active' : ''}`} id="menu">
                            <div className={`menu-header ${isSubMenuActive ? 'is-active' : ''}`}>
                                <span className="menu-arrow" onClick={hideSubMenu}><i className="bx bx-chevron-left"></i></span>
                                <span className="menu-title">{menuTitle}</span>
                            </div>
                            <ul className="menu-inner" onClick={toggleSubMenu}>
                                <li className="menu-item">
                                    <a target="_blank" className="menu-link" onClick={() => redirect(`/`)}>首页</a>
                                </li>
                                {pages && pages.flatMap((page, index) => {
                                    return (
                                        <React.Fragment key={page.categories.name + index}>
                                            {page.subPage.length > 0 ? (
                                                // with sub pages
                                                <li className="menu-item menu-dropdown" >
                                                    <span className="menu-link" >{page.categories.name}<i className="bx bx-chevron-right"></i></span>
                                                    <div className={`submenu megamenu megamenu-column-4 `}  >

                                                        {page.subPage.flatMap((subPage, subIndex) => {
                                                            const category = (subPage.categories.name === 'All' || subPage.categories.urlName === 'All') ? page.categories : subPage.categories
                                                            return (
                                                                <div className="submenu-inner" key={subPage.categories.name + subIndex}>
                                                                    <a className="submenu-link" onClick={() => handleDecodeUrlName(category)}>

                                                                        <span className="submenu-title">{subPage.categories.name}</span>
                                                                    </a>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                </li>
                                            ) : (
                                                // without sub pages
                                                <li className="menu-item" key={page.categories.name + index}>
                                                    <a className="menu-link" onClick={() => handleDecodeUrlName(page.categories)}>{page.categories.name}</a>

                                                </li>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </ul>
                        </div>
                    </section>
                    <section className="navbar-right">
                        <button className="switch" id="switch" onClick={toggleDarkMode}>
                            <span className="switch-light"><i className="bx bx-sun"></i></span>
                            <span className="switch-dark"><i className="bx bx-moon"></i></span>
                        </button>
                    </section>
                </nav>
            </header>
        </>
    )

}