import React, { useState, useEffect } from "react"
import apiClient from "../../instance/api-client"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SideWidgetNews(props) {
    const redirect = useNavigate()
    const { title, category } = props
    const [contents, setContents] = useState(null)
    // const [categories, setCategories] = useState({
    //     name: null,
    //     urlName: null,
    // })

    const updatedPost = useSelector(state => state.content.post)
    useEffect(() => {
        if (updatedPost.category) {
            const result = updatedPost.category.filter((categorys) => (categorys.name === props.category || categorys.urlName === props.category))

            if (result.length > 0) {
                const oldContent = [
                    ...contents,
                ]

                const newContentExist = oldContent.filter((content) => content.id === updatedPost.content.id)

                if (newContentExist.length === 0) {
                    const newContent = [
                        updatedPost.content,
                        ...oldContent
                    ]
                    setContents(null)
                    setContents(newContent.slice(0, 4))
                }
            }
        }
    }, [updatedPost])

    useEffect(() => {
        const loadCategoryPost = async () => {
            try {
                const response = await apiClient.get('contents/category/' + category, { params: { take: 4 } })

                if (response.status === 200) {
                    if (response.data && response.data.data[0] && response.data.data[0].categories[0]) {
                        // setCategories({
                        //     name: response.data.data[0].categories[0].name,
                        //     urlName: response.data.data[0].categories[0].urlName
                        // })
                        const data = response.data.data
                        setContents(data)
                    }
                }
            } catch (error) {
                console.info(error)
            }
        }

        loadCategoryPost()
    }, [category])


    // const formatDate = (dateString) => {
    //     const options = { month: "numeric", day: "numeric", year: "numeric" };
    //     const locale = 'zh-CN';
    //     const formattedDate = new Date(dateString).toLocaleDateString(locale, options);

    //     // Splitting the date based on the actual separator returned by toLocaleDateString
    //     const parts = formattedDate.split(/[\u200E/]/).filter(part => part.trim() !== '');

    //     return `${parts[1]} 月 ${parts[2]} 日`;
    // }

    const handleOnClick = (permLink) => {
        redirect(`/article/news/${permLink}`)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (<>
        <div className="sm:col-span-6 lg:col-span-4 aside-borders p-5">
            <h2 className="border-b-2 border-red-600 mb-4"><span
                className="bg-red-600 px-2 py-1 text-white1 uppercase tracking-wide">{title}</span></h2>

            {contents && contents.flatMap((content, index) => {

                return (
                    <div className="flex items-start mb-3 pb-3" key={index} onClick={() => handleOnClick(content.permLink)}>
                        <a className="inline-block mr-3">
                            <div className="w-20 h-20 bg-cover bg-center"
                                style={{ backgroundImage: `url(${`${process.env.REACT_APP_API_URL}uploads/${content.image.filename}`})` }}
                            ></div>
                        </a>
                        <div className="text-sm">
                            {/* <p className="text-gray-600 text-xs">{formatDate(content.createdAt)}</p> */}
                            <a
                                className="text-gray-900 font-medium hover:text-red-600 leading-none text-1xl text-ellipsis">{content.title}</a>
                        </div>
                    </div>
                )
            })}
        </div>
        <br />
    </>)


}