import React, { useState, useEffect } from "react";
import apiClient from '../../instance/api-client';

export default function Banner({ position, content }) {
    const show = true;
    let currentContent = null;
    let currentContent2 = null;
    const emptyBanner = { href: null, img: null }
    const [banner, setBanner] = useState({
        mainTop: { ...emptyBanner },
        mainMid: { ...emptyBanner },
        mainBtm: { ...emptyBanner },
        allTop: { ...emptyBanner },
        allSid: { ...emptyBanner },
        allBtm: { ...emptyBanner },
        contentTop: { ...emptyBanner },
        contentSid: { ...emptyBanner },
        contentBtm: { ...emptyBanner },
        // all: {
        //     href: null,
        //     img: null,
        // },
        // rightBottom: {
        //     href: null,
        //     img: null,
        // },
        side: {
            href: null,
            img: null,
        },
        qrCode: {
            href: null,
            img: null,
        }
    })

    const [isRightBottomBannerShow, setIsRightBottomBannerShow] = useState(true)

    const handleClose = () => {
        setIsRightBottomBannerShow(false)
    }

    useEffect(() => {
        const loadBanner = async () => {
            try {
                const response = await apiClient.get('banner')
                const data = response.data.reduce((acc, item) => {
                    acc[item.position] = {
                        href: item.href,
                        img: item.img
                    };
                    return acc;
                }, {});

                setBanner(data)
            } catch (error) {
                console.info(error)
            }
        }

        loadBanner()
    }, [])

    // console.info('position : ',position , banner[position].href)

    if (position === 'mainMid' || position === 'allBtm' || position === 'contentBtm') { // main middle or all/content bottom
        currentContent = (
            banner[position] ?
                <a href={banner[position].href} target="new" ><img className="mx-auto block w-screen-md my-0 sm:mt-0 p-0 sm:p-5" src={banner[position].img} alt='' /></a>
                :
                <img className="mx-auto block w-screen-md my-0 sm:mt-0 p-0 " src="/images/ad_banner-(1000x90).jpg" alt='' />

        );
    } else if (position === 'mainTop' || position === 'allTop' || position === 'contentTop') { // TOP
        currentContent = (
            banner[position] ?
                <a href={banner[position].href} target="new">
                    <img className="mx-auto block w-screen-md my-0 sm:mt-0 p-0 sm:p-5" src={`${banner[position].img}${new Date().getTime()}`} alt='' />
                </a>
                :
                <img className="mx-auto block w-screen-md my-0 sm:mt-0 p-0 sm:p-5" src="/images/ad_banner-(1070x290).jpg" alt='' />
        );
    } else if (position === 'mainBtm') {
        currentContent = (
            isRightBottomBannerShow ?
                banner[position] ?
                    <div id="features2" style={{ bottom: '0%' }}>
                        <span className="btn1" onClick={handleClose}><i className="fa fa-times" aria-hidden="true" ></i></span>
                        {/* <a href={banner.rightBottom.href} target="new">
                            <img src={`${banner.rightBottom.img}${new Date().getTime()}`} alt='' />
                        </a> */}
                        <a href={banner[position].href} target="new">
                            <img className="mx-auto block w-screen-md my-0 sm:mt-0 p-0 sm:p-5" src={`${banner[position].img}${new Date().getTime()}`} alt='aa' />
                        </a>
                    </div>
                    :
                    <div id="features" style={{ bottom: '0%' }}>
                        <span className="btn1" onClick={handleClose}><i className="fa fa-times" aria-hidden="true" ></i></span>
                        <img src="/images/ad_banner-(200x200).jpg" alt='bb' />
                    </div>
                : <></>
        );
    } else if (position === 'allSid' || position === 'contentSid') { // main bottom
        currentContent = (
            banner[position] ?
                <div >
                    <a href={banner[position].href} target="new">
                        <img src={`${banner[position].img}${new Date().getTime()}`} alt='' style={{ width: '100%' }} />
                    </a>
                </div>
                :
                <img style={{ width: '300px', height: '300px' }} src="/images/ad_banner-(300x300).jpg" alt='' />
        );
    } else if (position === 'qrCode') {
        currentContent = (
            banner.qrCode ?
                <a href={banner.qrCode.href} target="new">
                    <div style={{ position: 'relative' }}>
                        <img className="features-qr-img" src={`${process.env.REACT_APP_API_URL}uploads/${banner.qrCode.img}`} alt="" />
                        <img src={`${process.env.REACT_APP_API_URL}uploads/${content && content.image.filename}`} className="w-full" alt={content && content.image.alt} />
                    </div>
                </a>
                :
                <div style={{ position: 'relative' }}>
                    <img src={`${process.env.REACT_APP_API_URL}uploads/${content && content.image.filename}`} className="w-full" alt={content && content.image.alt} />
                </div>
        );
    } else {
        // Handle unknown position prop
        currentContent = <div>Unknown Banner Position</div>;
    }

    if (position === 'mainMid' || position === 'allBtm' || position === 'contentBtm') {
        currentContent2 = (
            <img className="mx-auto block w-screen-md my-0 sm:mt-0 p-0 sm:p-5" src="/images/ad_banner-(1000x90).jpg" alt='' />
        );
    } else if (position === 'mainTop' || position === 'allTop' || position === 'contentTop') {
        currentContent2 = (
            <img className="mx-auto block w-screen-md my-0 sm:mt-0 p-0 sm:p-5" src="/images/ad_banner-(1070x290).jpg" alt='' />
        );
    } else if (position === 'mainBtm') {
        currentContent2 = (
            isRightBottomBannerShow ?
            <div id="features2" style={{ bottom: '0%' }}>
                <span className="btn1"><i className="fa fa-times" aria-hidden="true" onClick={handleClose}></i></span>
                {/* <img src="/images/ad_banner-(200x200).jpg" alt='' /> */}
                <img className="mx-auto block w-screen-md my-0 sm:mt-0 p-0 sm:p-5" src="/images/ad_banner-(1070x290).jpg" alt='cc' />
            </div>
            :<></>
        );
    } else if (position === 'allSid' || position === 'contentSid') {
        currentContent2 = (
            <img src="/images/ad_banner-(300x300).jpg" alt='' />
        );
    } else if (position === 'qrCode') {
        currentContent2 = (
            <div style={{ position: 'relative' }}>
                <img src={`${process.env.REACT_APP_API_URL}uploads/${content && content.image.filename}`} className="w-full" alt={content && content.image.alt} />
            </div>
        );
    } else {
        // Handle unknown position prop
        currentContent2 = <div>Unknown Banner Position</div>;
    }

    // if (show && ((position === "main" && banner.main) || (position === "all" && banner.all) || (position === "rightBottom" && banner.rightBottom) || (position === "qrCode" && banner.qrCode) || (position === "side" && banner.side)))
    if (show && (banner[position] &&
        (position === "mainTop" || position === 'mainMid' ||
            position === 'mainBtm' || position === 'allTop' ||
            position === 'allBtm' || position === 'allSid' ||
            position === 'contentTop' || position === 'contentBtm' ||
            position === 'contentSid' || position === 'qrCode'))) {

        return currentContent;
    } else {
        return currentContent2
    }
}