import React, { useState, useEffect } from "react"
import apiClient from "../../instance/api-client"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SideWidgetFeatured(props) {
    const redirect = useNavigate()
    const { title, category } = props
    const [contents, setContents] = useState(null)
    // const [categories, setCategories] = useState({
    //     name: null,
    //     urlName: null,
    // })

    const updatedPost = useSelector(state => state.content.post)
    useEffect(() => {
        if (updatedPost.category) {
            const result = updatedPost.category.filter((categorys) => (categorys.name === props.category || categorys.urlName === props.category))

            if (result.length > 0) {
                const oldContent = [
                    ...(contents ? contents : []),
                ]

                const newContentExist = oldContent.filter((content) => content.id === updatedPost.content.id)
                if (newContentExist.length === 0) {
                    const newContent = [
                        updatedPost.content,
                        ...oldContent
                    ]
                    setContents(null)
                    setContents(newContent.slice(0, 1))
                }
            }
        }
    }, [updatedPost])

    useEffect(() => {
        const loadCategoryPost = async () => {
            try {
                const response = await apiClient.get('contents/category/' + category, { params: { take: 1 } })

                if (response.status === 200) {

                    if (response.data && response.data.data[0] && response.data.data[0].categories[0]) {
                        // setCategories({
                        //     name: response.data.data[0].categories[0].name,
                        //     urlName: response.data.data[0].categories[0].urlName
                        // })
                        const data = response.data.data//[0].contents.filter((content) => (content.id != null));
                        setContents(data)
                    }
                }
            } catch (error) {
                console.info(error)
            }
        }
        loadCategoryPost()
    }, [title, category])

    // const formatDate = (dateString) => {
    //     const options = { month: "numeric", day: "numeric", year: "numeric" };
    //     const locale = 'zh-CN';
    //     const formattedDate = new Date(dateString).toLocaleDateString(locale, options);
    //     // Splitting the date based on the actual separator returned by toLocaleDateString
    //     const parts = formattedDate.split(/[\u200E/]/).filter(part => part.trim() !== '');
    //     return `${parts[1]} 月 ${parts[2]} 日`;
    // }

    const handleOnClick = (permLink) => {
        redirect(`/article/news/${permLink}`)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (<>

        {contents && contents.flatMap((content, index) => {
            return (
                <div className="sm:col-span-6 lg:col-span-4 aside-borders p-5" key={index} onClick={() => handleOnClick(content.permLink)}>
                    <h2 className="border-b-2 border-indigo-700 mb-4"><span
                        className="bg-indigo-600 px-2 py-1 text-white1 uppercase tracking-wide">{title}</span></h2>
                    <a ><img src={`${process.env.REACT_APP_API_URL}uploads/${content.image.filename}`} className="w-full mb-4" alt='' /></a>
                    <h2 className="text-white text-2xl pb-4 leading-tight"><a
                        className="text-white">{content.title}</a></h2>
                    {/* <div className="text-gray-600 inline-block py-1 italic text-sm">发表于: <time dateTime="2021-11-19 20:00">{formatDate(content.createdAt)}</time> 由 <a className="underline hover:no-underline">{content.user.name}</a></div> */}
                </div>
            )
        })}


        <br />
    </>)
}