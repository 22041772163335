/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Header from "../component/header/header";
import Footer from "../component/footer/footer";
import ScrollToTop from "../component/scroll-to-top/scroll-to-top";
import { useNavigate } from "react-router-dom";
import apiClient from "../instance/api-client";
import io from "socket.io-client";
// import HeroSection from '../component/hero-section/hero-section';
import Banner from "../component/banner/banner";
// import HeroSectionWithAllImage from '../component/hero-section/hero-section-with-all-image';
// import ListView from '../component/list-view/list-view';
import { useDispatch, useSelector } from "react-redux";
import { setUpdateContent } from "../component/redux/action/post-action";
import { setHeaderMeta } from "../component/redux/action/meta-action";
import HeroSectionWithoutNumber from "../component/hero-section/hero-section-without-number";
import SEO from "../component/seo/seo";

export default function MainPage() {
  const redirect = useNavigate();
  const [mainContent, setMainContent] = useState([]);
  const [gameSchedule, setGameSchedule] = useState(false);
  const dispatch = useDispatch();
  const [meta, setMeta] = useState({
    title: "",
    desc: "",
    keyword: "",
  });

  const pageMeta = useSelector((state) => state.meta.meta);

  useEffect(() => {
    if (pageMeta.content) {
      const temp = pageMeta.content[0];
      setMeta({
        title: temp.mainTitle,
        desc: temp.mainDesc,
        keyword: temp.mainKey,
      });
    }
  }, [pageMeta]);

  useEffect(() => {
    const socket = io.connect(process.env.REACT_APP_WS_URL);

    socket.on("post", (data) => {
      dispatch(setUpdateContent(data.content, data.category));
      loadContentPage();
    });
    let origin = window.location.origin;
    origin = origin.replace("http://", "").replace("https://", "");
    const exist = /www\../.test(origin);
    if (!exist) {
      origin = "www." + origin;
    }

    loadTDK(origin);
  }, []);

  const loadTDK = async (domain) => {
    if (!pageMeta.meta) {
      const response = await apiClient.get("server-hosting?domain=" + domain);

      if (response.data && response.data.data) {
        dispatch(setHeaderMeta(response.data.data));
      }
    }
    loadContentPage();
    loadGameSchedule();
  };

  const loadContentPage = async () => {
    try {
      const response = await apiClient.get("contents", { params: { take: 4 } });
      if (response.data && response.data.data)
        setMainContent(response.data.data);
    } catch (error) {
      console.info(error);
    }
  };

  const loadGameSchedule = async () => {
    // try {
    //   // const response = await fetch("https://api2-euro.o88j36.xyz/schedules/euro_2024/live_coming_matches").then((res) => res.json())
    //   const response = await fetch(
    //     "https://api.yjyj987.com/olympics_2024/schedules/live_coming_matches"
    //   ).then((res) => res.json());

    //   if (response.status === 1) {
    //     // console.info(response.result.coming.slice(0, 5))
    //     setGameSchedule(response.result.coming.slice(0, 5));
    //   }

    //   // if (response.data && response.data.data)
    //   //     setMainContent(response.data.data)
    // } catch (error) {
    //   console.info(error);
    // }
  };

  return (
    <>
      <Banner position={"mainBtm"} />

      <Header pageCallBack={() => {}} />
      {/* for main page display  */}
      <SEO
        type={"article"}
        title={meta.title}
        description={meta.desc}
        keyword={meta.keyword}
        name={process.env.REACT_APP_DOMAIN_NAME}
      />

      <ScrollToTop />

      <div className="max-w-screen-xl mx-auto px-4 py-24">
        <Banner position={"mainTop"} />
        <div className="flex flex-col flex-wrap md:flex-row md:-mx-3">
          <main className="w-full lg:w-full mb-8 lg:mb-0">
            <div className="lna-news-day-home__section">
              <div className="lna-news-group">
                {mainContent &&
                  (mainContent[0] ? (
                    <div className="lna-news-group__column">
                      <div className="lna-news-block">
                        <a
                          className="lna-news-block__link-container"
                          target="_blank"
                          onClick={() =>
                            redirect(`/article/news/${mainContent[0].permLink}`)
                          }
                        >
                          <img
                            src={`${process.env.REACT_APP_API_URL}uploads/${
                              mainContent[0] && mainContent[0].image.filename
                            }`}
                            className="lna-news-block__image"
                            alt={mainContent[0].image.alt}
                          />
                          <div className="lna-news-block__content-wrapper">
                            <div className="lna-news-block__title">
                              {mainContent[0].categories &&
                                mainContent[0].categories
                                  .slice(0, 4)
                                  .flatMap((category, index) => {
                                    return (
                                      <span
                                        className={
                                          index === 0
                                            ? "lna-news-block__category"
                                            : "lna-news-block__category-epl"
                                        }
                                        data-text-value={category.name}
                                        key={index}
                                      >
                                        {category.name}
                                      </span>
                                    );
                                  })}

                              <div className="lna-news-block__title-text">
                                {mainContent[0].title}
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  ) : (
                    <img
                      className="mx-auto block w-screen-md my-0 sm:mt-0 p-0 sm:p-5"
                      src="/images/coming-soon.jpg"
                      alt=""
                    />
                  ))}
                <div className="lna-news-group__column">
                  {mainContent && mainContent[1] && (
                    <div className="lna-news-group__first-row">
                      <div className="lna-news-block">
                        <a
                          className="lna-news-block__link-container"
                          target="_blank"
                          onClick={() =>
                            redirect(`/article/news/${mainContent[1].permLink}`)
                          }
                        >
                          <img
                            src={`${process.env.REACT_APP_API_URL}uploads/${
                              mainContent[1] && mainContent[1].image.filename
                            }`}
                            className="lna-news-block__image"
                            alt={mainContent[1].image.alt}
                          />
                          <div className="lna-news-block__content-wrapper">
                            <div className="lna-news-block__title">
                              {mainContent[1].categories &&
                                mainContent[1].categories
                                  .slice(0, 4)
                                  .flatMap((category, index) => {
                                    return (
                                      <span
                                        className={
                                          index === 0
                                            ? "lna-news-block__category"
                                            : "lna-news-block__category-epl"
                                        }
                                        data-text-value={category.name}
                                        key={index}
                                      >
                                        {category.name}
                                      </span>
                                    );
                                  })}
                              <div className="lna-news-block__title-text">
                                {mainContent[1].title}
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  )}
                  <div className="lna-news-group__second-row">
                    {mainContent && mainContent[2] && (
                      <div className="lna-news-block">
                        <a
                          className="lna-news-block__link-container"
                          target="_blank"
                          onClick={() =>
                            redirect(`/article/news/${mainContent[2].permLink}`)
                          }
                        >
                          <img
                            src={`${process.env.REACT_APP_API_URL}uploads/${
                              mainContent[2] && mainContent[2].image.filename
                            }`}
                            className="lna-news-block__image"
                            alt={mainContent[2].image.alt}
                          />
                          <div className="lna-news-block__content-wrapper">
                            <div className="lna-news-block__title">
                              {mainContent[2].categories &&
                                mainContent[2].categories
                                  .slice(0, 4)
                                  .flatMap((category, index) => {
                                    return (
                                      <span
                                        className={
                                          index === 0
                                            ? "lna-news-block__category"
                                            : "lna-news-block__category-epl"
                                        }
                                        data-text-value={category.name}
                                        key={index}
                                      >
                                        {category.name}
                                      </span>
                                    );
                                  })}
                              <div className="lna-news-block__title-text">
                                {mainContent[2].title}
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    )}
                    {mainContent && mainContent[3] && (
                      <div className="lna-news-block">
                        <a
                          className="lna-news-block__link-container"
                          target="_blank"
                          onClick={() =>
                            redirect(`/article/news/${mainContent[3].permLink}`)
                          }
                        >
                          <img
                            src={`${process.env.REACT_APP_API_URL}uploads/${
                              mainContent[3] && mainContent[3].image.filename
                            }`}
                            className="lna-news-block__image"
                            alt={mainContent[3].image.alt}
                          />
                          <div className="lna-news-block__content-wrapper">
                            <div className="lna-news-block__title">
                              {mainContent[3].categories &&
                                mainContent[3].categories
                                  .slice(0, 4)
                                  .flatMap((category, index) => {
                                    return (
                                      <span
                                        className={
                                          index === 0
                                            ? "lna-news-block__category"
                                            : "lna-news-block__category-epl"
                                        }
                                        data-text-value={category.name}
                                        key={index}
                                      >
                                        {category.name}
                                      </span>
                                    );
                                  })}
                              <div className="lna-news-block__title-text">
                                {mainContent[3].title}
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {gameSchedule && (
              <div
                class="eventhomebanner_event_home_banner__1evlX"
                style={{ marginTop: "10px" }}
              >
                <div
                  class="rs-flex-box-grid rs-flex-box-grid-middle rs-flex-box-grid-start"
                  style={{ alignItems: "stretch", height: "auto" }}
                >
                  <div
                    class="rs-flex-box-grid-item rs-flex-box-grid-item-4"
                    style={{ position: "relative", zIndex: 2 }}
                  >
                    <img
                      class="eventhomebanner_banner__IGi5R"
                      src={`${process.env.REACT_APP_API_URL}uploads/1721877964525OlympicsBanner[1].png`}
                      alt="EURO2024"
                    />
                    <div class="eventhomebanner_fade__2ZMRr"></div>
                    <div class="eventhomebanner_eventbutton__v1vH0">
                      <a
                        href="https://kqkq11.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        更多赛事
                      </a>
                    </div>
                  </div>
                  <div class="eventhomebanner_match_card_wrapper__1c5Gv rs-flex-box-grid-item rs-flex-box-grid-item-18">
                    {gameSchedule.flatMap((gameMatch) => {
                      const matchTime = gameMatch.match_date_time.split(" ");
                      return (
                        <div class="eventhomebanner_match_card__10uCa">
                          <div class="eventhomebanner_match_card__team__3DU51">
                            <div class="eventhomebanner_match_card__fringe__2wnOv">
                              <span>{matchTime[0]}</span>
                              <span>{matchTime[1]}</span>
                            </div>
                            <div class="rs-avatar rs-avatar-md rs-avatar-circle">
                              {gameMatch.home.logo && (
                                <img
                                  class="rs-avatar-image"
                                  src={gameMatch.home.logo}
                                  alt={gameMatch.home.name_zh}
                                />
                              )}
                            </div>
                            <p>{gameMatch.home.name_zh}</p>
                          </div>
                          <div class="eventhomebanner_match_card__score__1r-_5">
                            <span>
                              {gameMatch.home.score.full_time +
                                gameMatch.home.score.half_time}
                            </span>
                            <span>VS</span>
                            <span>
                              {gameMatch.away.score.full_time +
                                gameMatch.away.score.half_time}
                            </span>
                          </div>
                          <div class="eventhomebanner_match_card__team__3DU51">
                            <div class="rs-avatar rs-avatar-md rs-avatar-circle">
                              {gameMatch.away.logo && (
                                <img
                                  class="rs-avatar-image"
                                  src={gameMatch.away.logo}
                                  alt={gameMatch.away.name_zh}
                                />
                              )}
                            </div>
                            <p> {gameMatch.away.name_zh}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              // <div class="eventhomebanner_event_home_banner__CfPY2" style={{ marginTop: '10px' }}>
              //     <div class="rs-flex-box-grid rs-flex-box-grid-middle rs-flex-box-grid-start" style={{ alignItems: 'stretch', height: 'auto' }}>
              //         <div class="rs-flex-box-grid-item rs-flex-box-grid-item-6" style={{ position: 'relative', zIndex: 2 }}>
              //             <img class="eventhomebanner_banner__12zbC" src={`${process.env.REACT_APP_API_URL}uploads/1721877964525OlympicsBanner[1].png`} style={{ height: '180px' }} alt="EURO2024" />
              //             <div class="eventhomebanner_fade__xG510">
              //             </div>
              //             <div class="eventhomebanner_eventbutton__3zGra">
              //                 <a href='https://zdu17lv.com/d' target="_blank" rel="noreferrer">
              //                     更多赛事</a>
              //             </div>
              //         </div>
              //         <div class="eventhomebanner_match_card_wrapper__13jke rs-flex-box-grid-item rs-flex-box-grid-item-18">

              //             {gameSchedule.flatMap((gameMatch) => {
              //                 const matchTime = gameMatch.match_date_time.split(" ")
              //                 return (
              //                     <div class="eventhomebanner_match_card__2bBlL">
              //                         <div class="eventhomebanner_match_card__team__1ETgf">
              //                             <div class="eventhomebanner_match_card__fringe__3YB_c">
              //                                 <span>{matchTime[0]}</span>
              //                                 <span>{matchTime[1]}</span>
              //                             </div>
              //                             <div class="rs-avatar rs-avatar-md rs-avatar-circle">
              //                                 <img class="rs-avatar-image" src={gameMatch.home.logo} alt={gameMatch.home.name_zh} />
              //                             </div>
              //                             <p>
              //                                 {gameMatch.home.name_zh}
              //                             </p>
              //                         </div>
              //                         <div class="eventhomebanner_match_card__score__DIrBq">
              //                             <span>
              //                                 {gameMatch.home.score.full_time + gameMatch.home.score.half_time}
              //                             </span>
              //                             <span>
              //                                 VS
              //                             </span>
              //                             <span>
              //                                 {gameMatch.away.score.full_time + gameMatch.away.score.half_time}
              //                             </span>
              //                         </div>
              //                         <div class="eventhomebanner_match_card__team__1ETgf">
              //                             <div class="rs-avatar rs-avatar-md rs-avatar-circle">
              //                                 <img class="rs-avatar-image" src={gameMatch.away.logo} alt={gameMatch.away.name_zh} />
              //                             </div>
              //                             <p> {gameMatch.away.name_zh}</p>
              //                         </div>
              //                     </div>
              //                 )
              //             })}

              //         </div>
              //     </div>
              // </div>
            )}

            <HeroSectionWithoutNumber
              title="热门资讯"
              category="hn"
              logo={false}
            />
            <Banner position={"mainMid"} />
            <HeroSectionWithoutNumber
              title="联赛最新动态"
              category="hn"
              logo={false}
            />

            <HeroSectionWithoutNumber
              title="国际赛事"
              category="gjss"
              logo={false}
            />
          </main>
        </div>
      </div>
      <Footer />
    </>
  );
}
