import React, { useEffect, useState } from "react"
import apiClient from "../../instance/api-client"
import { ReactComponent as BookIcon } from "../../component/svg/book-icon.svg"
import { useNavigate } from "react-router-dom";

export default function HeroSectionNews(props) {
    const { title, category } = props
    const [contentList, setContentList] = useState(null)
    const redirect = useNavigate()

    useEffect(() => {
        loadPageCategory()
    }, [title, category])

    const loadPageCategory = async () => {
        try {
            const response = await apiClient.get('contents/category/' + category)

            if (response.status === 200 && response.data.data) {
                setContentList(response.data.data)
            }
        } catch (error) {
            console.info(error)
        }
    }

    // const formatDate = (dateString) => {
    //     const options = { month: "numeric", day: "numeric", year: "numeric" };
    //     const locale = 'zh-CN';
    //     const formattedDate = new Date(dateString).toLocaleDateString(locale, options);

    //     // Splitting the date based on the actual separator returned by toLocaleDateString
    //     const parts = formattedDate.split(/[\u200E/]/).filter(part => part.trim() !== '');

    //     return `${parts[1]} 月 ${parts[2]} 日`;
    // }

    const handleOnClick = (permLink) => {
        redirect(`/article/news/${permLink}`)
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (<article className="md:mx-3 bg-blue-50 shadow-lg mt-8 p-8 bg-white">
        <div className="max-w-screen-xl mx-auto relative">
            <div className="border-b mb-5 flex justify-between text-sm">
                <div className="text-indigo-600 flex items-center pb-2 pr-2 border-b-2 uppercase">
                    <BookIcon />

                    <a className="font-semibold inline-block">其他新闻</a>
                </div>
                <a onClick={() => redirect(`/${category}`)}>查看全部</a>
            </div>
            <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-2">
                {contentList && contentList.flatMap((content, index) => (
                    <div className="flex" key={index}>
                        <a onClick={() => handleOnClick(content.permLink)} className="text-gray-900  hover:text-indigo-600 text-truncate" style={{minWidth:'unset'}}>{content.title}</a>
                    </div>
                ))}
            </div>
        </div>
    </article>)

}