
import React, { useEffect, useState } from 'react'
import Header from '../component/header/header'
import Footer from '../component/footer/footer'
import ScrollToTop from '../component/scroll-to-top/scroll-to-top'
import apiClient from '../instance/api-client';
import SideWidgetNews from '../component/side-widget/news';
import SideWidgetFeatured from '../component/side-widget/featured';
import SideWidgetHeadline from '../component/side-widget/headline';
import ListViewComponent from '../component/list-view/list-view-component';
import Banner from '../component/banner/banner';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderMeta } from '../component/redux/action/meta-action'
import SEO from '../component/seo/seo';
export default function Pages() {
    const dispatch = useDispatch()
    const redirect = useNavigate()
    const [contenteList, setContentList] = useState([])
    const [pathName, setPathName] = useState('')
    const [meta, setMeta] = useState(null)
    const [categories, setCategories] = useState({
        name: null,
        urlName: null,
        subName: null,
        subUrlName: null,
    })
    const [page, setPage] = useState(null)
    const [pagingMeta, setPagingMeta] = useState({
        page: 1,
        take: 10
    })
    const [otherCategories, setOtherCategories] = useState({
        name: null,
        urlName: null,
    })

    const updatedPost = useSelector(state => state.content.post)
    const pageMeta = useSelector(state => state.meta.meta)

    useEffect(() => {
        if (updatedPost.category) {
            const result = updatedPost.category.filter((category) => (category.name === pathName || category.urlName === pathName))
            if (result.length > 0) {
                const oldContent = [
                    ...(contenteList || []),
                ]

                const newContentExist = oldContent.filter((content) => content.id === updatedPost.content.id)
                if (newContentExist.length === 0) {
                    setContentList(null)
                    loadPageCategory(pathName, 1)
                }
            }
        }
    }, [updatedPost])

    useEffect(() => {
        if (!pageMeta.content) {
            const loadTDK = async (domain) => {
                if (!pageMeta.meta) {
                    const response = await apiClient.get('server-hosting?domain=' + domain)
                    if (response.data && response.data.data) {
                        dispatch(setHeaderMeta(response.data.data))
                    }
                    loadAll(pathName)
                }
            }

            let origin = window.location.origin
            origin = origin.replace('http://', '').replace('https://', '')
            const exist = /www\../.test(origin)
            if (!exist) {
                origin = 'www.' + origin
            }
            loadTDK(origin)
        } else {
            loadAll(pathName)
        }
    }, [pageMeta])


    useEffect(() => {
        setContentList([])
        const param = window.location.href
        const decodedString = decodeURIComponent(param);
        const url = new URL(decodedString);
        const pathname = decodeURIComponent(url.pathname)
        const pathnameParts = pathname.split('/').filter(part => part !== '');
        loadAll(pathnameParts[0])
        // loadPageCategory(pathnameParts[0])
        // loadPageMeta(pathnameParts[0])
        window.scrollTo({ top: 0, behavior: 'instant' });
        // pageCallBack(page)
    }, [window.location.href])

    const loadAll = (pathName) => {
        loadPageCategory(pathName)
        loadPageMeta(pathName)
        pageCallBack(page)
    }

    const loadPageCategory = async (pathnameParts, pages) => {
        setPathName(pathnameParts)
        try {
            if (pathnameParts) {
                const response = await apiClient.get('contents/category/' + pathnameParts, { params: { take: 10, page: pages } })

                if (response.status === 200) {
                    if (contenteList) {
                        if (response.data && response.data.data)
                            setContentList((prev) => [...(prev ? prev : []), ...response.data.data]);
                    } else {
                        if (response.data && response.data.data)
                            setContentList(response.data.data);
                    }
                    setPagingMeta(response.data.meta)
                }
            }

        } catch (error) {
            console.info(error)
        }
    }

    const loadPageMeta = async (pathnameParts) => {
        if (pathnameParts)
            try {
                const response = await apiClient.get('pages/search/' + pathnameParts)

                if (response.status === 200) {


                    if (response.data.data[0]) {
                        setCategories({
                            name: response.data.data[0].categories_name,
                            urlName: response.data.data[0].categories_url_name,
                            subName: response.data.data[0].subPage_categories_name,
                            subUrlName: response.data.data[0].subPage_categories_url_name
                        })
                        // setMeta(response.data.data[0])

                        if (pageMeta.content && pageMeta.content[0]) {
                            const tempMeta = pageMeta.content[0]
                            switch (response.data.data[0].categories_url_name) {
                                case 'football-situation-articles':
                                    setMeta({ title: tempMeta.footTitle, desc: tempMeta.footDesc, keyword: tempMeta.footKey })
                                    break;
                                case 'League-news':
                                    setMeta({ title: tempMeta.leagueTitle, desc: tempMeta.leagueDesc, keyword: tempMeta.leagueKey })
                                    break;
                                case 'international-events':
                                    setMeta({ title: tempMeta.intTitle, desc: tempMeta.intDesc, keyword: tempMeta.intKey })
                                    break;
                                default:
                                    break;
                            }
                        }
                    } else {
                        loadCategoryDetail(pathnameParts)
                    }
                }
            } catch (error) {
                console.info(error)
            }
    }

    const pageCallBack = (pages) => {
        if (pages) {
            setPage(pages)
            const pathnameParts = decodeURIComponent(window.location.pathname).split('/').filter(part => part !== '');
            const categoryTest = pages.filter((category) => (category.categories.urlName !== pathnameParts[0] && category.categories.name !== pathnameParts[0]))
            setOtherCategories({
                name: categoryTest[0].categories.name,
                urlName: categoryTest[0].categories.urlName
            })
        }
    }

    const loadCategoryDetail = async (category) => {
        try {
            const response = await apiClient.get('categories/searchOnly/' + category)
            if (response.status === 200) {
                if (response.data.data[0])
                    setCategories({
                        name: response.data.data[0].categories_name,
                        urlName: response.data.data[0].categories_url_name,
                    })
            }

        } catch (error) {
            console.info(error)
        }
    }

    const loadNextPage = () => {
        if (pagingMeta.hasNextPage) {
            loadPageCategory(pathName, pagingMeta.page + 1)
        }
    }

    return (
        <>
            <Banner position={'mainBtm'} />
            <Header pageCallBack={pageCallBack} />
            <ScrollToTop />


            <SEO
                type={'article'}
                title={meta && meta.title}
                description={meta && meta.desc}
                keyword={meta && meta.keyword}
                name={process.env.REACT_APP_DOMAIN_NAME}
            />

            {/* for content list display */}
            <div className="max-w-screen-xl mx-auto">

                <div className="scp-breadcrumb">
                    <ul className="breadcrumb">
                        <li><a onClick={() => redirect('/')}>首页</a></li>
                        {(categories.urlName || categories.name) && (<li><a onClick={() => redirect(`/${categories.urlName ? categories.urlName : categories.name}`)}>{categories.name}</a></li>)}

                        <li className="active">{categories.subName}</li>
                    </ul>
                </div> <div className="max-w-screen-xl mx-auto px-4 py-24">


                    <div className="flex flex-col flex-wrap md:flex-row md:-mx-3">

                        <div className="w-full lg:w-3/4 mb-6 lg:mb-0">
                            <Banner position={'allTop'} />
                            {(contenteList && contenteList.length > 0) ?
                                <div className="bg-white p-5">
                                    {contenteList && contenteList.flatMap((content, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <ListViewComponent content={content} pathName={pathName} keys={index} />
                                            </React.Fragment>
                                        )
                                    })}
                                    {pagingMeta && pagingMeta.hasNextPage && (<div className="flex items-center" ><a style={{ position: "relative", left: "50%" }} onClick={loadNextPage}>More</a></div>)}
                                </div>
                                :
                                (
                                    <img className="mx-auto block w-screen-md my-0 sm:mt-0 p-0 sm:p-5" src="/images/coming-soon.jpg" alt='' />
                                )}
                            <Banner position={'allBtm'} />

                        </div>

                        <aside className="w-full lg:w-1/4">
                            <div className="md:mx-3 sticky top-6" id="__FPSC_ID_1_1641419081408">

                                <SideWidgetNews title="相关新闻" category="足球变动" />

                                <SideWidgetFeatured title="精选" category="热门资讯" />

                                <SideWidgetHeadline title="头条新闻" category={otherCategories.urlName ? otherCategories.urlName : otherCategories.name} />

                                <Banner position={'allSid'} />
                            </div>
                        </aside>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}