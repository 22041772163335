import React from "react";

export default function ScrollToTop() {

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        // <a id="button" className="show" onClick={scrollToTop}></a>
        <button
            id="button"
            className="show"
            onClick={scrollToTop}
            style={{ background: '#e7e7e7', border: 'none', color: 'white', textDecoration: 'underline', cursor: 'pointer', }}>
        </button>

    )
}